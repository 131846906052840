const TIMEOUT = 2000

export async function gtm (e): Promise<void> {
  window.dataLayer = window.dataLayer ?? []

  return await new Promise(resolve => {
    const timeout = setTimeout(() => resolve(), TIMEOUT)

    const eventCallback = (): (containerId: string) => void => {
      const fired = []
      return function (containerId: string) {
        fired.push(containerId)
        if (fired.some(id => id?.startsWith('GTM-')) && fired.some(id => id?.startsWith('G-'))) {
          clearTimeout(timeout)
          resolve()
        }
      }
    }

    const params = {
      ...e,
      eventCallback: eventCallback(),
      eventTimeout: TIMEOUT
    }

    window.dataLayer.push(params)
  })
}
