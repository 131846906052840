import { gtm } from './gtm'

export type Event = 'decision' | 'view' | 'registration'

// sends an {event, experimentt, variant} to /api/events via sendBeacon if available or via XHR
export const send = async (event: Event, experiment: string, variant: string): Promise<void> => {
  const payload = JSON.stringify({
    event,
    experiment,
    variant,
    uri: window.location.href
  })

  if ('sendBeacon' in navigator) {
    navigator.sendBeacon('https://optimize.clickocean.io/api/events', payload)
  } else {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', 'https://optimize.clickocean.io/api/events', true)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(payload)
  }

  if (event === 'view') {
    await gtm({
      event: 'experiment_impression',
      optimize: {
        experiment_id: experiment,
        variant_id: variant
      }
    })
  }
}
