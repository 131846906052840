export interface RedirectTimings {
  originalPageDuration: number
  originalSdkStart: number
  originalChoiceDuration: number
  variantRedirectDuration: number
  variantPageDuration: number
  variantSdkStart: number
  variantPageTotalDuration: number
}

export interface Metrics {
  label: 'redirect'
  metrics: RedirectTimings
}

export const send = async ({ label, metrics }: Metrics, tags: { [key: string]: string } = {}): Promise<void> => {
  const payload = JSON.stringify({ uri: window.location.href, metrics, tags })

  if ('sendBeacon' in navigator) {
    navigator.sendBeacon(`https://optimize.clickocean.io/api/metrics/${label}`, payload)
  } else {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', `https://optimize.clickocean.io/api/events/${label}`, true)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(payload)
  }
}
